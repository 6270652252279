<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" @change="(event) => updateMachTypeDropdown(event)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="divisionModel" :items="divisionLists" default="" item-value="class" item-text="descr" label="Select Division" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="machTypeModel" :items="machTypeLists" default="" item-value="mach_type" item-text="descr" label="Select Mach. Type" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph1">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                    <div class="modal-body">
                        <h5 class="card-title text-white">Result Period : {{ this.periodtext }}</h5>
                        <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Delivery Analysis',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            divisionModel: '',
            divisionLists: [],
            machTypeModel: '',
            machTypeLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false,
            itemDetailLists: [],
            headersDetails: [
                { text: 'MILL', value: 'mill_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER ID', value: 'Order_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DELIV. ID', value: 'deliv_id', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVE DATE', value: 'dt_rcv', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SHIP. DATE', value: 'ship_date', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'DIFFERENCE', value: 'selisih', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            searchItem: '',
            periodtext: '',
            dateFromFormatted: '',
            dateToFormatted: ''

        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            this.dateFrom = this.currentPeriod()
            this.dateTo = this.currentPeriod()

            document.querySelector(".cardGraph1").style.display = "none";

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/DeliveryAnalysis`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                this.millLists = res.data.mill
                this.divisionLists = res.data.division
                this.machTypeLists = res.data.machType
            })
        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph1").style.display = "none"
            

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/DeliveryAnalysis/getChartData`, { 

                mill: this.millModel ? this.millModel : '',
                division: this.divisionModel ? this.divisionModel : '',
                quality: this.qualityModel ? this.qualityModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                machID: this.machIDModel ? this.machIDModel : '',
                dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                dateTo: this.dateToFormatted ? this.dateToFormatted : '',

            },
            {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            }
            )
            .then(res => {

                if(res.data.arr_list1.length != 0){
                    document.querySelector(".cardGraph1").style.display = "block"

                    console.log(res.data.result)
                    this.renderChart1(res.data.arr_list1, res.data.arr_list2, res.data.arr_list3, res.data.arr_list4)
                    
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }

            })

        },

        async getDataDetails(e){

            $('#detailModal').modal('show')
            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/DeliveryAnalysis/getDataDetails`, { 

                mill: this.millModel ? this.millModel : '',
                division: this.divisionModel ? this.divisionModel : '',
                machType: this.machTypeModel ? this.machTypeModel : '',
                period: e.dataPoint.label

            },
            {
                headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            }).then(res => {
                this.periodtext = e.dataPoint.label
                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result

                console.log(res.data.result)

            })

        },

        renderChart1(data1, data2, data3, data4){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                title: {
                    text: "Delivery Analysis (Monthly)",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                axisY: {
                    scaleBreaks: {
                        autoCalculate: true,
                        type: "wavy"
                    },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Days"
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.getDataDetails,
                        name: "Average",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#556052",
                        dataPoints: data1
                    },
                    {
                        type: "column",
                        click: this.getDataDetails,
                        name: "Max",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#8f384d",
                        dataPoints: data2
                    },
                    {
                        type: "column",
                        click: this.getDataDetails,
                        name: "Min",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#132743",
                        dataPoints: data3
                    },
                    {
                        type: "column",
                        click: this.getDataDetails,
                        name: "Modus",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#00b7c2",
                        dataPoints: data4
                    },
                ]
            })

            chart1.render()

        },

        async updateMachTypeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kka/DeliveryAnalysis/getMachineByMill?mill=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kka/DeliveryAnalysis/getMachineByMill?mill=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.machTypeLists = []
                    this.machTypeLists = res.data.machType
                })
            }
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}`;      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>